import { IDriverExtended } from "src/bffmodels/IDriverExtended";
import { cdataFetch } from "../cdataFetch";

export function getConnectionModel(params: {
  driverType: string;
  connectionId: string;
}) {
  return cdataFetch<IDriverExtended | undefined>({
    method: "GET",
    url: `/account/connection/${params.driverType}/${params.connectionId}`,
  });
}
